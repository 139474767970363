import React,{Component} from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd All Hosting Package';
class Payment extends React.Component{
    render(){
        return(
            <div>
                <h1>Hi fokinni</h1>
            </div>
        );
    }

}
export default Payment;