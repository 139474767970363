import React,{Component} from 'react';

class About extends React.Component{
    render(){
        return(
            <div>
                
            </div>
        );
    }

}
export default About;