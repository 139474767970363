import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd Contact with us';
class Contact extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="no-bottom no-top" id="content">
                    <div id="top"></div>
                    {/* <!-- revolution slider begin --> */}
                    {/* <!-- section begin --> */}
                    <section className="relative no-top no-bottom text-light" style={{ backgroundImage: "url(" + "./assets/images/background/10.jpg" + ")" }} data-stellar-background-ratio=".2">

                        <div className="overlay-gradient t70">
                            <div className="center-y">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 offset-md-2 text-center">
                                            <div className="col text-center">
                                                <div className="spacer-single"></div>
                                                <h1 className="no-bottom">Contact Us</h1>
                                                <p className="lead">Ask any questions using form below.</p>
                                            </div>
                                        </div>

                                        <div className="spacer-10"></div>

                                        <div className="col-lg-8 mb-sm-30">			
							{/* <form name="contactForm" id='contact_form' className="form-s1" method="post" action='email.php'>

                                    <div className="field-set">
                                        <input type='text' name='name' id='name' className="form-control" placeholder="Your Name"/>
                                    </div>

                                    <div className="field-set">
                                        <input type='text' name='email' id='email' className="form-control" placeholder="Your Email"/>
                                    </div>

                                    <div className="field-set">
                                        <input type='text' name='phone' id='phone' className="form-control" placeholder="Your Phone"/>
                                    </div>

                                    <div className="field-set">
                                        <textarea name='message' id='message' className="form-control" placeholder="Your Message"></textarea>
                                    </div>

                                    <div className="spacer-half"></div>

                                    <div id='submit'>
                                        <input type='submit' id='send_message' value='Submit Form' className="btn btn-custom"/>
                                    </div>
                                    <div id='mail_success' className='success'>Your message has been sent successfully.</div>
                                    <div id='mail_fail' className='error'>Sorry, error occured this time sending your message.</div>

                                </form> */}
                                <iframe width="600" height="450" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=%20Middle%20Badda&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title=" Middle Badda" aria-label=" Middle Badda" frameborder="0">
                                        </iframe>

						</div>
                                        <div className="col-sm-4 mb30">

                                            <div className="padding40 bg-gradient-to-right-2 text-light rounded">
                                                <h3>Address</h3>
                                                <address className="s1">
                                                    <span><i className="fa fa-map-marker fa-lg"></i>Badda Alatunnessa School road,Middle Badda,Dhaka-1212</span>
                                                    {/* <span><i className="fa fa-phone fa-lg"></i>+1 333 9296</span>
												<span><i className="fa fa-envelope-o fa-lg"></i><a href="mailto:contact@example.com">contact@example.com</a></span>
												<span><i className="fa fa-file-pdf-o fa-lg"></i><a href="#">Download Brochure</a></span> */}
                                                </address>
                                            </div>

                                            <div className="spacer-30"></div>

                                            <div className="padding40 bg-gradient-to-right text-light rounded">
                                                <h3>Phone Number</h3>
                                                <address className="s1">
                                                    {/* <span><i className="fa fa-map-marker fa-lg"></i>100 Mainstreet Center, Sydney</span> */}
                                                    <span><i className="fa fa-phone fa-lg"></i>+8801858764472</span>
                                                    <span><i className="fab fa-whatsapp fa-lg"></i>+8801858764472</span>
                                                    {/* <span><i className="fa fa-envelope-o fa-lg"></i><a href="mailto:contact@example.com">contact@example.com</a></span>
												<span><i className="fa fa-file-pdf-o fa-lg"></i><a href="#">Download Brochure</a></span> */}
                                                </address>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    {/* <!-- section close --> */}

                </div>
            </div>
        );
    }

}
export default Contact;