import React,{Component} from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd All Hosting Package';
class Hosting extends React.Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <div id="content" class="no-top no-bottom">
           
           <section class="no-top no-bottom text-light" style={{backgroundImage: "url(" + "./assets/images/background/12.jpg" + ")"}} data-stellar-background-ratio=".2">
               <div class="overlay-gradient t80">
                   <div class="center-y mt50">
                       <div class="container">
                           <div class="row align-items-center">
                               <div class="col-md-12 text-center">
                                   <h1>Choose Your Hosting Plan</h1>						
                                   <div class="switch-set">
                                       <div>Monthly</div>								
                                       <div><input id="sw-1" class="switch" type="checkbox" /></div>					
                                       <div>Yearly</div>
                                       <div class="spacer-20"></div>
                                   </div>
                                   
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section class="no-bg no-top mt-80">
               <div class="container">
                                   <div class="row">
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s2 mb30">
                                               <div class="top">
                                                   <img src="(./assets/images/icon-shared-hosting.png)" class="size96" alt=""/>
                                                   <div class="inner">
                                                   <div class="clearfix"></div>
                                                   <h2>Shared Hosting</h2>
                                                   <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                   <p class="price"><span class="txt">Start from</span><span class="currency">$</span>
                                                   <span class="num opt-1">5.59</span> 
                                                   <span class="num opt-2">3.45</span>
                                                   <span class="month">p/mo</span></p>
                                                   <a href="" class="btn-custom">Sign Up Now</a>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s2 rec mb30">
                                               <div class="inner">
                                                   <div class="ribbon">PROMO</div>
                                                   <div class="top">
                                                   <img src="(./assets/images/icon-vps-hosting.png)" class="size96" alt=""/>
                                                   <div class="clearfix"></div>
                                                   <h2>VPS Hosting</h2>
                                                   <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                   <p class="price"><span class="txt">Start from</span><span class="currency">$</span>
                                                   <span class="num opt-1">14.75</span>
                                                   <span class="num opt-2">12.25</span>
                                                   <span class="month">p/mo</span></p>
                                                   <a href="" class="btn-custom">Sign Up Now</a>
                                                   <div class="clearfix"></div>
                                               </div>
                                               </div>                                                
                                           </div>
                                       </div>
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s2 mb30">
                                               <div class="inner">
                                               <div class="top">
                                                    <img src="(./assets/images/icon-cloud-hosting.png)" class="size96" alt=""/>
                                                   <div class="clearfix"></div>
                                                   <h2>Cloud Hosting</h2>
                                                   <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                   <p class="price"><span class="txt">Start from</span><span class="currency">$</span>
                                                   <span class="num opt-1">18.65</span>
                                                   <span class="num opt-2">16.35</span>
                                                   <span class="month">p/mo</span></p>
                                                   <a href="" class="btn-custom mb10">Sign Up Now</a>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
           </section>
           
           <section id="section-features" class="bg-gradient-to-right">
               <div class="container">
                   <div class="row">
                       <div class="col-md-12 text-center text-light">
                           <h2>Hosting Features</h2>							
                           <div class="spacer-20"></div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                           <div class="feature-box-type-1">
                               <i class="icon-alarmclock"></i>
                               <div class="text">
                                   <h3>Instant Activation</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".75s">
                           <div class="feature-box-type-1 hover">
                               <i class="icon-profile-male"></i>
                               <div class="text">
                                   <h3 class="">24/7 Support</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay="1s">
                           <div class="feature-box-type-1">
                               <i class="icon-refresh"></i>
                               <div class="text">
                                   <h3>99.99 Uptime</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                       
                        <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".7s">
                           <div class="feature-box-type-1">
                               <i class="icon-tools-2"></i>
                               <div class="text">
                                   <h3>High Performance</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay=".95s">
                           <div class="feature-box-type-1">
                               <i class="icon-layers"></i>
                               <div class="text">
                                   <h3 class="">Multiple Data Centers</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30 wow fadeInRight" data-wow-delay="1.2s">
                           <div class="feature-box-type-1">
                               <i class="icon-shield"></i>
                               <div class="text">
                                   <h3>Money Back Warranty</h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section id="section-faq">
               <div class="container">
                   <div class="row">
                       <div class="col text-center">
                           <h2>General Questions</h2>
                           <div class="spacer-20"></div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-md-10 offset-md-1">
                           <div class="accordion">
                               <div class="accordion-section">
                                   <div class="accordion-section-title" data-tab="#accordion-1">
                                       How do I get started with web hosting?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-1">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-2">
                                       What is difference for each plan?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-2">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-3">
                                       What kind of web hosting do I need?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-3">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-4">
                                       Why do I need domain name?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-4">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-5">
                                       What my website protected from hackers?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-5">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-6">
                                       How do I backup my website?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-6">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section id="section-banner" class="no-bottom no-top img-fluid bg-gradient-to-right text-light relative pos-top">
               <div class="container">
                   <div class="row align-items-center">
                       <div class="col-lg-4 d-none d-lg-block d-xl-block  text-center">
                           <img class="relative img-fluid mt-60" src="./assets/images/misc/1.png" alt=""/>
                       </div>

                       <div class="col-lg-4 col-md-6">
                           <div class="p-sm-30 pb-sm-0 mb-sm-0">
                               <h2>30 days money back satisfication guarantee</h2>
                           </div>
                       </div>

                       <div class="col-lg-4 col-md-6">
                           <div class="p-sm-30">
                           <p class="lead">If you not satisfy with Uhost, we will refund your payment. No hassle, no risk. You can cancel plan at any time.</p>
                           <div class="spacer-half"></div>
                           <a class="btn-custom" href="#">Get Started</a>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </div>
            </div>
        );
    }

}
export default Hosting;