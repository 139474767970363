import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd Domain Pricing';
class Domain extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<title>{TITLE}</title>
				</Helmet>
				<div class="no-bottom no-top" id="content">
					<div id="top"></div>
					{/* <!-- section begin --> */}
					<section class="full-height relative no-top no-bottom text-light" style={{ backgroundImage: "url(" + "./assets/images/background/2.jpg" + ")" }} data-stellar-background-ratio=".2">

						<div class="overlay-gradient t80 ">
							<div class="center-y relative text-center" data-scroll-speed="4">
								<div class="container">
									<div class="row">
										<div class="col-md-8 offset-md-2">
											<form action="https://portal.hostbd.us/cart.php?a=add&domain=register" method="post" class="row" id='form_sb' name="myForm">
												<div class="col text-center">
													<h1 class="no-bottom">Search for your perfect domain name</h1>
													<p class="lead">Reserve your domain today before someone take it.</p>
													<div class="spacer-10"></div>
													<input id="inputDomain" class="form-control bar" name="domain" type="text" placeholder="Enter your domain name" required="" />
													{/* <button class="btn button" type="submit" id="btn-submit"> <i class="arrow_right"></i> </button> */}
													<a href="https://portal.hostbd.us/cart.php?a=add&domain=register" id="btn-submit"><i class="arrow_right"></i></a>
													<div class="clearfix"></div>
													<div class="spacer-10"></div>
													<div class="domain-ext">
														<div class="ext">
															<h4>.com</h4> ৳880/year
                                            </div>

														<div class="ext">
															<h4>.net</h4> ৳599/year
                                            </div>

														<div class="ext">
															<h4>.xyz</h4> ৳99/year
                                            </div>

														<div class="ext">
															<h4>.info</h4> ৳390/year
                                            </div>

														<div class="ext">
															<h4>.org</h4> ৳899/year
                                            </div>
													</div>
												</div>
												<div class="clearfix"></div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>

					</section>
					{/* <!-- section close --> */}


					<section id="domains">
						<div class="container">
							<div class="row">
								<div class="col-lg-6 col-md-12 text-center">

									<h3>Domain Pricing</h3>

									<div class="spacer-20"></div>

									<div class="border-primary" style={{border:'solid #2372e8 5px',borderRadius:'5px'}}>
										<div class="content">
											<table class="table">
												<thead>
													<tr>
														<th scope="col">TLD</th>
														<th scope="col">Register</th>
														<th scope="col">Renew</th>
														<th scope="col">Transfer</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<th scope="row">.com</th>
														<td>850tk</td>
														<td>850tk</td>
														<td>850tk</td>
													</tr>
													<tr>
														<th scope="row">.net</th>
														<td>699tk</td>
														<td>1099tk</td>
														<td>1099tk</td>
													</tr>
													<tr>
														<th scope="row">.org</th>
														<td>899tk</td>
														<td>1099tk</td>
														<td>1099tk</td>
													</tr>
													<tr>
														<th scope="row">.info</th>
														<td>399tk</td>
														<td>1499tk</td>
														<td>1499tk</td>
													</tr>
													<tr>
														<th scope="row">.co</th>
														<td>1099tk</td>
														<td>2299tk</td>
														<td>2299tk</td>
													</tr>
													<tr>
														<th scope="row">.xyz</th>
														<td>99tk</td>
														<td>950tk</td>
														<td>950tk</td>
													</tr>
													<tr>
														<th scope="row">.city</th>
														<td>595tk</td>
														<td>1899tk</td>
														<td>1899tk</td>
													</tr>
													<tr>
														<th scope="row">.pw</th>
														<td>150tk</td>
														<td>1799tk</td>
														<td>1799tk</td>
													</tr>
													<tr>
														<th scope="row">.online</th>
														<td>200tk</td>
														<td>799tk</td>
														<td>799tk</td>
													</tr>
													<tr>
														<th scope="row">.shop</th>
														<td>200tk</td>
														<td>2699tk</td>
														<td>2699tk</td>
													</tr>
												</tbody>
											</table>

										</div>
									</div>
								</div>

								<div class="col-lg-6 col-md-12 text-center">
									<h3>Featured Domain</h3>

									<div class="spacer-20"></div>

									<div class="box-highlight s2">
										<div class="content">

											<div class="de_table table-style-2 text-left no-heading cols-2">
												<div class="tr">
													<div class="td">.com</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.com" class="btn-custom">৳ 850tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.net</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.net" class="btn-custom">৳ 699tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.org</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.org" class="btn-custom">৳ 899tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.info</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.info" class="btn-custom">৳ 399tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.co</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.co" class="btn-custom">৳ 200tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.xyz</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.xyz" class="btn-custom">৳ 110tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.city</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.city" class="btn-custom">৳ 595tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.pw</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.pw" class="btn-custom">৳ 150tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.online</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.online" class="btn-custom">৳ 200tk</a></div>
												</div>
												<div class="tr">
													<div class="td">.shop</div>
													<div class="td"><a href="https://portal.hostbd.us/cart.php?a=add&domain=register&query=.shop" class="btn-custom">৳ 200tk</a></div>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>
						</div>
					</section>

					<section id="section-banner" class="no-bottom no-top img-fluid bg-gradient-to-right text-light relative pos-top">
						<div class="container">
							<div class="row align-items-center">
								<div class="col-lg-4 d-none d-lg-block d-xl-block  text-center">
									<img class="relative img-fluid mt-60" src="./assets/images/misc/1.png" alt="" />
								</div>

								<div class="col-lg-4 col-md-6">
									<div class="p-sm-30 pb-sm-0 mb-sm-0">
										<h2>30 days money back satisfication guarantee</h2>
									</div>
								</div>

								<div class="col-lg-4 col-md-6">
									<div class="p-sm-30">
										<p class="lead">If you not satisfy with Uhost, we will refund your payment. No hassle, no risk. You can cancel plan at any time.</p>
										<div class="spacer-half"></div>
										<a class="btn-custom" href="https://portal.hostbd.us/cart.php?gid=1">Get Started</a>
									</div>
								</div>
							</div>
						</div>
					</section>


				</div>
			</div>
		);
	}

}
export default Domain;