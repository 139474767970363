import React,{Component} from 'react';
import { Link,NavLink } from 'react-router-dom';
class Header extends React.Component{
    render(){
        return(
            <div>
                <header class="header-s1 has-topbar">
			<div id="topbar" class="text-white">
				<div class="container">
					<div class="topbar-left">
						
					</div>
				
					<div class="topbar-right">
						<div class="topbar-widget sm-hide"><a href="tel:+8801875221779"><i class="fa fa-phone"></i>+8801875221779</a></div>
						<div class="topbar-widget sm-hide"><a href="mailto:hostbd.us@gmail.com"><i class="fa fa-envelope-o"></i>Support</a></div>
					</div>	
					<div class="clearfix"></div>
				</div>
			</div>
		
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="header-row">
                            <div class="header-col left">
                                {/* <!-- logo begin --> */}
                                <div id="logo">
                                    <a href="/"><img alt="" class="logo" src="./assets/images/logo-light.png"/> <img alt="" class="logo-2" src="assets/images/logo-light.png"/></a>
                                </div>
                                {/* <!-- logo close --> */}
                            </div>
                            <div class="header-col mid">
                                {/* <!-- mainmenu begin --> */}
                                <ul id="mainmenu">
                                    <li><a href='/'>Home</a></li>
                                    <li><a href='#'>Hosting</a>
                                        <ul>
                                            <li><Link to='shared'>Shared Hosting</Link></li>
                                            <li><Link to='reseller'>Reseller Hosting</Link></li>
                                            {/* <li><Link to='/vps'>VPS Hosting</Link></li> */}
                                        </ul>
                                    </li>
                                    <li><Link to='domain'>Domain</Link></li>
									<li><NavLink to='faq'>FAQ</NavLink></li>   
                                    <li><Link to='contact'>Contact</Link></li>
                                </ul>
                                <div class="col-right">
                                    <a class="btn-custom" href="https://portal.hostbd.us/clientarea.php"><i class="fa fa-lock"></i> Login</a>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        {/* <!-- small button begin --> */}
                        <span id="menu-btn"></span>
                        {/* <!-- small button close --> */}

                    </div>
                </div>
            </div>
        </header>
            </div>
        );
    }

}
export default Header;