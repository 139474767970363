import React,{Component} from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd 404 Not found';
class Notfound extends React.Component{
    render(){
        return(
            <div>
               <Helmet>
					<title>{TITLE}</title>
				</Helmet>
                <div id="content" class="no-top no-bottom">
            {/* <!-- section begin --> */}
            <section aria-label="section-maintenance" class="full-height relative no-top no-bottom text-light" data-stellar-background-ratio=".2" style={{backgroundImage: "url(" + "./assets/images/background/7.jpg" + ")"}}>

                <div id="particles-js"></div>

                <div class="overlay-gradient t80">
                    <div class="center-y fadeScroll relative text-center" data-scroll-speed="4">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 offset-md-2">
                                  
                                        <img class="logo wow fadeInUp ultra-big" data-wow-delay="1s" src="images/logo-light.png" alt=""/>
                                   
                                    <div class="spacer-single"></div>
                                    <div class="mask">
                                        <h1 class="wow fadeInUp ultra-big" data-wow-delay="1.2s">404</h1>
                                    </div>
									<div class="spacer-10"></div>
                                    <div class="mask">
                                        <h3 class="wow fadeInUp" data-wow-delay="1.4s">Not found</h3>
                                    </div>
                                    <div class="spacer-double"></div>
                                    <div class="social-icons wow fadeInUp ultra-big" data-wow-delay="1.8s">
                                        <a href="https://www.facebook.com/hostbdus/"><i class="fa fa-facebook fa-lg"></i></a>
                                        <a href="https://twitter.com/hostbdus/"><i class="fa fa-twitter fa-lg"></i></a>
                                        <a href="#"><i class="fa fa-rss fa-lg"></i></a>
                                        <a href="#"><i class="fa fa-google-plus fa-lg"></i></a>
                                        <a href="#"><i class="fa fa-skype fa-lg"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* <!-- section close --> */}
        </div>
            </div>
        );
    }

}
export default Notfound;