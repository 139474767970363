import React,{Component} from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd VPS Hosting Packages';
class Vps extends React.Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <div id="content" class="no-top no-bottom">
           
           <section class="no-top no-bottom text-light" style={{backgroundImage: "url(" + "./assets/images/background/12.jpg" + ")"}} data-stellar-background-ratio=".2">
               <div class="overlay-gradient t80">
                   <div class="center-y mt50">
                       <div class="container">
                           <div class="row align-items-center">
                               <div class="col-lg-6 col-12 text-lg-left text-center mb-sm-30">
                                   <h1>VPS Hosting<span class="label">5% OFF</span></h1>
                                   <p class="lead">We provide best hosting solutions for your hosting needs. Our clients from personal to corporate. Our data center are all over the world to ensure your website is always up. Happy hosting!
</p>
                                   <div class="spacer-half"></div>
                                   <a class="btn-custom" href="#plans">See All Plans</a>
                               </div>
                               
                               <div class="col-lg-3 offset-lg-2 col-4 offset-4 text-center">
                                   <img src="./assets/images/big-icon-vps-hosting.png" class="img-fluid" alt=""/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section id="plans">
               <div class="container">
                   <div class="row">
                       <div class="col text-center">
                           <h2>VPS Hosting Plans</h2>						
                           <div class="switch-set">
                               <div>Monthly</div>								
                               <div><input id="sw-1" class="switch" type="checkbox" /></div>					
                               <div>Yearly</div>
                               <div class="spacer-20"></div>
                           </div>
                           
                       </div>
                   </div>
                           <div class="item pricing">
                               <div class="container">
                                   <div class="row">
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s1 mb30">
                                               <div class="top">
                                                   <h2>VPS Business</h2>
                                                   <p class="price">
                                                       <span class="txt">Start from</span>
                                                       <span class="currency">৳</span>
                                                       <span class="m opt-1">5.59</span>
                                                       <span class="y opt-2">3.46</span>
                                                       <span class="month">/mo</span>
                                                   </p>               
                                               </div>
                                               
                                               <div class="bottom">

                                                   <ul>
                                                       <li><i class="fa fa-check-square"></i>1x Processing Power</li>
                                                       <li><i class="fa fa-check-square"></i>1 Website</li>
                                                       <li><i class="fa fa-check-square"></i>1GB Disk Space</li>
                                                       <li><i class="fa fa-check-square"></i>Easy Website Builder</li>
                                                       <li><i class="fa fa-check-square"></i>99.9% Uptime Guarantee</li>
                                                   </ul>
                                               </div>
                                               
                                               <div class="action">
                                                   <a href="" class="btn-custom">Sign Up Now</a>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s1 mb30">
                                               <div class="top">
                                                   <h2>VPS Professional</h2>
                                                   <p class="price">
                                                       <span class="txt">Start from</span>
                                                       <span class="currency">৳</span>
                                                       <span class="m opt-1">9.59</span>
                                                       <span class="y opt-2">7.46</span>
                                                       <span class="month">/mo</span>
                                                   </p>     
                                               </div>
                                               <div class="bottom">
                                                   <ul>
                                                       <li><i class="fa fa-check-square"></i>6x Processing Power</li>
                                                       <li><i class="fa fa-check-square"></i>Unlimited Website</li>
                                                       <li><i class="fa fa-check-square"></i>100GB Disk Space</li>
                                                       <li><i class="fa fa-check-square"></i>Easy Website Builder</li>
                                                       <li><i class="fa fa-check-square"></i>99.9% Uptime Guarantee</li>
                                                   </ul>
                                               </div>
                                               
                                               <div class="action">
                                                   <a href="" class="btn-custom">Sign Up Now</a>
                                               </div>
                                           </div>
                                       </div>
                                       <div class="col-lg-4 col-md-6 col-sm-12">
                                           <div class="pricing-s1 mb30">
                                               <div class="top">
                                                   <h2>VPS Enterprise</h2>
                                                   <p class="price">
                                                       <span class="txt">Start from</span>
                                                       <span class="currency">৳</span>
                                                       <span class="m opt-1">24.99</span>
                                                       <span class="y opt-2">16.49</span>
                                                       <span class="month">/mo</span>
                                                   </p>     
                                               </div>
                                               <div class="bottom">
                                                   <ul>
                                                       <li><i class="fa fa-check-square"></i>6x Processing Power</li>
                                                       <li><i class="fa fa-check-square"></i>Unlimited Website</li>
                                                       <li><i class="fa fa-check-square"></i>100GB Disk Space</li>
                                                       <li><i class="fa fa-check-square"></i>Easy Website Builder</li>
                                                       <li><i class="fa fa-check-square"></i>99.9% Uptime Guarantee</li>
                                                   </ul>
                                               </div>
                                               
                                               <div class="action">
                                                   <a href="" class="btn-custom">Sign Up Now</a>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
               </div>
           </section>
           
           <section id="section-features" class="bg-gradient-to-right">
               <div class="container">
                   <div class="row">
                       <div class="col-md-12 text-center text-light">
                           <h2>Shared Hosting Features</h2>							
                           <div class="spacer-20"></div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1">
                               <i class="icon-alarmclock"></i>
                               <div class="text">
                                   <h3>Instant Activation</h3>After completing payment your service will active immediately.Within minutes you will be ready to use your web hosting account.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1 hover">
                               <i class="icon-profile-male"></i>
                               <div class="text">
                                   <h3 class="">24/7 Support</h3>We provide 24×7/365 Free Customer support via Email, Support ticket, Live Chat also instantly phone call support.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1">
                               <i class="icon-refresh"></i>
                               <div class="text">
                                   <h3>99.99% Uptime</h3>The best data centers and ensure your server has the latest security patches. That's why we can commit to your site being up over 99.9% of the time.
                               </div>
                           </div>
                       </div>
                       
                        <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1">
                               <i class="icon-tools-2"></i>
                               <div class="text">
                                   <h3>High Performance</h3>We provides one-stop web-acceleration solutions with Ultimate in website performance.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1">
                               <i class="icon-layers"></i>
                               <div class="text">
                                   <h3 class="">Multiple Data Centers</h3>We have 5 data center in the world to provide you downless service.1 Asian,2 USA, 2 Uk.
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-md-6 mb30">
                           <div class="feature-box-type-1">
                               <i class="icon-shield"></i>
                               <div class="text">
                                   <h3>Money Back Warranty</h3>We provide service very well but unfortunately if you are not satisfied with our service Cancel within 30 days.
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section id="section-faq">
               <div class="container">
                   <div class="row">
                       <div class="col text-center">
                           <h2>General Questions</h2>
                           <div class="spacer-20"></div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-md-10 offset-md-1">
                           <div class="accordion">
                               <div class="accordion-section">
                                   <div class="accordion-section-title" data-tab="#accordion-1">
                                       How do I get started with web hosting?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-1">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-2">
                                       What is difference for each plan?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-2">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-3">
                                       What kind of web hosting do I need?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-3">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-4">
                                       Why do I need domain name?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-4">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-5">
                                       What my website protected from hackers?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-5">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                                   <div class="accordion-section-title" data-tab="#accordion-6">
                                       How do I backup my website?
                                   </div>
                                   <div class="accordion-section-content" id="accordion-6">
                                       <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                           sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
           
           <section id="section-banner" class="no-bottom no-top img-fluid bg-gradient-to-right text-light relative pos-top">
               <div class="container">
                   <div class="row align-items-center">
                       <div class="col-lg-4 d-none d-lg-block d-xl-block  text-center">
                           <img class="relative img-fluid mt-60" src="./assets/images/misc/1.png" alt=""/>
                       </div>

                       <div class="col-lg-4 col-md-6">
                           <div class="p-sm-30 pb-sm-0 mb-sm-0">
                               <h2>30 days money back satisfication guarantee</h2>
                           </div>
                       </div>

                       <div class="col-lg-4 col-md-6">
                           <div class="p-sm-30">
                           <p class="lead">If you not satisfy with Uhost, we will refund your payment. No hassle, no risk. You can cancel plan at any time.</p>
                           <div class="spacer-half"></div>
                           <a class="btn-custom" href="#">Get Started</a>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </div>
            </div>
        );
    }

}
export default Vps;