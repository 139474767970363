import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Payment from './pages/Payment';
import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Maintainance from './pages/Maintainance';
import Hosting from './hosting/Hosting';
import Faq from './pages/Faq';
import Domain from './pages/Domain';
import Shared from './hosting/Shared';
import Reseller from './hosting/Reseller';
import Vps from './hosting/Vps';
import NotFound from './pages/Notfound'
function App() {
  return (
    <div>
      <Router>
      <Header/>
      <Switch>
        <Route exact path='/'>
          <Home/>
        </Route>
        <Route path='/about'>
        <About/>
        </Route>
        <Route path='/maintainance'>
        <Maintainance/>
        </Route>
        <Route path='/contact'><Contact/></Route>
        <Route path='/payment'><Payment/></Route>
        <Route path='/hosting'><Hosting/></Route>
        <Route path='/faq' component={Faq}></Route>
        <Route path='/domain'><Domain/></Route>
        <Route path='/shared'><Shared/></Route>
        <Route path='/reseller'><Reseller/></Route>
        <Route path='/vps'><Vps/></Route>
        <Route component={NotFound} />
      </Switch>
      <Footer/>
      </Router>
    </div>
    
  );
}

export default App;
