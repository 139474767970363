import React,{Component} from 'react';
import { Helmet } from 'react-helmet';
const TITLE = 'Hostbd FAQ';
class Faq extends React.Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>{ TITLE }</title>
                </Helmet>
                <div class="no-bottom no-top" id="content">
            <div id="top"></div>
            {/* <!-- revolution slider begin -->
            <!-- section begin --> */}
            <section class="relative no-top no-bottom text-light" style={{backgroundImage: "url(" + "./assets/images/background/6.jpg" + ")"}} data-stellar-background-ratio=".2">

                <div class="overlay-gradient t80">
                    <div class="center-y relative text-center" data-scroll-speed="4">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 offset-md-2">
                                <div class="col text-center">
									<div class="spacer-single"></div>
									<h1>HostBD FAQ</h1>
									
									<div class="spacer-single"></div>
									
                                    <form action="https://portal.hostbd.us/cart.php?a=add&domain=register" method="post" class="row" id='form_sb' name="myForm">
									<div class="col text-center">
										<div class="spacer-10"></div>
										<input id="inputDomain" class="form-control bar" name="domain" type="text" placeholder="Enter your domain name" required="" />
													{/* <button class="btn button" type="submit" id="btn-submit"> <i class="arrow_right"></i> </button> */}
													<a href="https://portal.hostbd.us/cart.php?a=add&domain=register" id="btn-submit"><i class="arrow_right"></i></a>
									</div>
									</form>
									
									<div class="spacer-20"></div>
									
									<p>eg. register domain, create email.</p>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* <!-- section close --> */}
            <section id="section-features">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 mb30">
							<div class="box-highlight">
										<div class="heading text-center text-white"><h3>Shared Hosting</h3></div>
										<div class="content">
											
											<div class="accordion">
                                <div class="accordion-section">
                                    <div class="accordion-section-title" data-tab="#accordion-1">
                                    What is a shared hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-1">
                                        <p>Shared hosting is Web hosting in which the service provider serves pages for multiple Web sites, each having its own Internet domain name, from a single Web server.Although shared hosting is a less expensive way for businesses to create a web presence, it is usually not sufficient for web sites with high traffic.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-2">
                                    How do i transfer web pages to server?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-2">
                                        <p>The most convenient way to transfer your website’s files to your new web hosting account is via FTP/File manager. You should connect to your hosting account and download the files locally to your computer. Then you have to upload them to your new hosting account’s main folder (usually public_html, www or httpdocs).</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-3">
                                    Purchased a hosting, now what do i do?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-3">
                                        <p>The first thing to consider is whether you want to use the hosting for what kind of website. Hostbd.us will help you to make your desiar website.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-4">
                                    How do i purchase a hosting for my hosting account?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-4">
                                        <p>Login to <a href="https://portal.hostbd.us/clientarea.php">Clientarea</a> click order new service</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-5">
                                    What kind of web hosting plan do i need?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-5">
                                        <p>It depends on what kind of website do you need,If you wanted to make a normal sire, shared hosting is better,If you want wrdpress or blog site wordpress hosting package is better,If you wanted to make download site then dedicated serer is better. Hostbd.us provide all kind of service,our SSD hosting package comes with ecommerce environment.</p>
                                    </div>

                                </div>
                            </div>
											
										</div>
									</div>
                        </div>
						
						<div class="col-md-6 mb30">
							<div class="box-highlight s2">
										<div class="heading text-center text-white"><h3>Domain</h3></div>
										<div class="content">
											
											<div class="accordion">
                                <div class="accordion-section">
                                    <div class="accordion-section-title" data-tab="#accordion-b-1">
                                    What is a domain name?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-b-1">
                                        <p>A domain name is an identification string that defines a realm of administrative autonomy, authority or control within the Internet. Domain names are formed by the rules and procedures of the Domain Name System (DNS).</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-b-2">
                                    Why do i need a domain name?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-b-2">
                                        <p>Domain Names. On the Internet, your domain name is your unique identity. Any individual, business or organization planning to have an Internet presence should invest in a domain name. Having your own domain name, website and email addresses will give you and your business a more professional look.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-b-3">
                                    Can I buy a domain name forever?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-b-3">
                                        <p>In the case of getting a new website address, while you cannot buy domain names outright or permanently, what you can do is to register a name.this is another myth we can bust – you cannot buy a domain name forever; however, you can continually renew registration to maintain control over it. </p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-b-4">
                                    What is the difference between a domain name and hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-b-4">
                                        <p>Domain Name vs Web Hosting. To simplify: A domain name, is like the address of your home, web hosting on the other hand, is the space of your house where you place your furniture. Instead of street name and area code, set of words or/and numbers are used for the website’s naming.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-b-5">
                                    Which nameserver I have to set for HostBD hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-b-5">
                                        <p>You have to set ns1.hostbd.us and ns2.hostbd.us as you domain nameserver.</p>
                                    </div>

                                </div>
                            </div>
											
										</div>
									</div>
                        </div>
                        
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 mb30">
							<div class="box-highlight">
										<div class="heading text-center text-white"><h3>VPS Hosting</h3></div>
										<div class="content">
											
											<div class="accordion">
                                <div class="accordion-section">
                                    <div class="accordion-section-title" data-tab="#accordion-c-1">
                                    What is a VPS hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-c-1">
                                        <p>VPS hosting is a Virtual Private Server, and is a virtualized server. A VPS hosting environment mimics a dedicated server within a shared hosting environment. It is technically both shared hosting and dedicated hosting.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-c-2">
                                    How does VPS Hosting Work?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-c-2">
                                        <p>The technology behind VPS hosting is similar to that of VMware or Virtual Box.These programs allow you to run several virtualized operating systems on one machine. For example, your desktop may be running Windows 7, but you can also run other operating systems such as Windows XP or Linux without needing to restart your computer.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-c-3">
                                    What are the benefits of VPS Hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-c-3">
                                        <p>VPS hosting gives you the affordability of shared hosting while at the same time giving you more power and control like a dedicated server.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-c-4">
                                    Why is VPS Hosting different than Shared and Dedicated?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-c-4">
                                        <p>With our dedicated servers, you rent an entire server. This is optimal for people that have very high traffic to their websites or need to setup their server in a very specific way. Not everyone needs to have a fully dedicated web server however. If you’re just getting started with your website, you can save quite a bit of money if you rent a small portion of the server. Shared hosting is when you share a portion of the server with other users rather than rent an entire server to yourself.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-c-5">
                                    What is the difference between cloud and VPS?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-c-5">
                                        <p>A Cloud server can be called as a VPS server but a VPS is not a cloud server.The biggest difference between the two server environments is scale.If you’re looking to launch as quickly as possible and don’t care about scale, then a VPS server can be a great starting point. However, if you demand a flexible hosting setup and a high level of site performance and storage then it’s worth checking out a cloud hosting environment.</p>
                                    </div>

                                </div>
                            </div>
											
										</div>
									</div>
                        </div>
						
						<div class="col-md-6 mb30">
							<div class="box-highlight s2">
										<div class="heading text-center text-white"><h3>Dedicated Hosting</h3></div>
										<div class="content">
											
											<div class="accordion">
                                <div class="accordion-section">
                                    <div class="accordion-section-title" data-tab="#accordion-d-1">
                                    What is a dedicated hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-d-1">
                                        <p>Dedicated hosting is an Internet hosting option in which an organization leases an entire server, which is often housed in a data center. The host not only provides the server equipment, but may also provide administration and other services. This is considered a more flexible arrangement for the client because unlike in shared server arrangements, it gives the organization total control over the server, its software and security systems. In some cases, a dedicated server can be less expensive. Dedicated hosting is also known as a dedicated server or managed hosting service.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-d-2">
                                    What dose it mean to have a dedicated server?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-d-2">
                                        <p>A dedicated server is a single computer in a network reserved for serving the needs of the network. For example, some networks require that one computer be set aside to manage communications between all the other computers.In the Web hosting business, a dedicated server is typically a rented service.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-d-3">
                                    What is the difference between dedicated and vps hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-d-3">
                                        <p>Virtual private servers are different from dedicated servers in that instead of running one hosting account on one computer/server, virtual private servers share a single server. This may cause one to wonder what the difference is between a VPS solution and a shared web host. </p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-d-4">
                                    What is the difference between shared and dedicated hosting?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-d-4">
                                        <p>A dedicated hosting plan means that your website is the only site hosted on the server. Bandwidth or Disk Space: With shared hosting, the amount of disk space and bandwidth you are allotted is limited because there are others sharing the server. You will be charged if you surpass you allotted amount.</p>
                                    </div>
                                    <div class="accordion-section-title" data-tab="#accordion-d-5">
                                    What is dedicated bandwidth vs shared bandwidth?
                                    </div>
                                    <div class="accordion-section-content" id="accordion-d-5">
                                        <p>Shared Internet is probably what your business and home are equipped with right now; a single Internet service shared among users, which can then be accessed on various devices and internal networks. On a shared Internet connection, all bandwidth (5 Mbps, 10 Mbps, etc.) is split among all users and devices. </p>
                                    </div>

                                </div>
                            </div>
											
										</div>
									</div>
                        </div>
                        
                    </div>
                </div>
            </section>

        </div>
            </div>
        );
    }

}
export default Faq;